import { logEvent } from 'firebase/analytics'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import { AuthGuard } from 'src/features/auth/AuthGuard'
import OIDCLogin from 'src/features/auth/login/Login'
import { firebaseAnalytics } from 'src/features/firebase/firebase'
import { Login } from 'src/features/login/Login'
import Maintenance from 'src/features/maintenance/Maintenance'
import { RootState } from 'src/state/store'
import { appEnv } from 'src/util/appEnv'
import { RoutesPrivate } from 'src/views/RoutesPrivate'

/**
 * ルーティングを設定しているコンポーネント
 *
 * 認証が必要な画面へのルーティングは別コンポーネントに切り出している。
 */
const Routes: React.FC = () => {
  const location = useLocation()
  const user = useSelector((state: RootState) => state.usersState.user.user)

  useEffect(() => {
    if (!appEnv.isLocal) {
      const pagePath = location.pathname + location.search
      logEvent(firebaseAnalytics, 'page_view', {
        page_path: pagePath,
        company_id: user?.company.id,
        user_id: user?.id,
      })
    }
  }, [location, user?.company.id, user?.id])

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/auth/login" component={OIDCLogin} />
      <Route exact path="/maintenance" component={Maintenance} />
      <AuthGuard>
        <RoutesPrivate />
      </AuthGuard>
    </Switch>
  )
}

export default Routes
