import { Form, InputNumber, Row, Select, Switch } from 'antd'
import styled from 'styled-components'

export const FormGroup = styled.div`
  margin-bottom: 25px;
`

export const SubTypeFormItem = styled(Form.Item)`
  width: 90px;
`

export const NumberMinimumFormItem = styled(Form.Item)`
  width: 90px;
  &&& {
    margin-right: 0px;
  }
`

export const NumberMaximumFormItem = styled(Form.Item)`
  width: 90px;
  &&& {
    margin-right: 0px;
  }
`

export const BetweenFormItem = styled(Form.Item)`
  width: 35px;
  text-align: center;
  &&& {
    margin-right: 0px;
  }
`

export const ScaleFormItem = styled(Form.Item)`
  width: 90px;
  &&& {
    margin-left: 16px;
  }
`

export const NumberSubTypeSelect = styled(Select)`
  && {
    width: 90px;
  }
`

export const FormGroupDecimalPointRow = styled(Row)`
  align-items: center;
  margin-bottom: 12px;
`

export const FormGroupDecimalPointValueRow = styled(Row)`
  display: flex;
  flex-direction: column;
`

export const FormGroupDecimalPointValue = styled.div`
  margin-bottom: 12px;
`

export const FormGroupDecimalPointValueInstruction = styled.div``

export const DecimalPointSwitch = styled(Switch)`
  &.ant-switch {
    margin-right: 12px;
  }
`
export const DecimalPointInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 60px;
    margin-right: 12px;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`
