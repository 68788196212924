import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons'
import * as Sentry from '@sentry/browser'
import {
  InformationFile,
  ResponseTypeEnum,
  ResponseURL,
  TemplateNodeSchema,
  TemplateQuestion,
} from '@ulysses-inc/harami_api_client'
import { Button, Input, Select, Spin, Tooltip } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import mime from 'mime'
import React, { useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getMBSize } from 'src/exShared/util/fileSize'
import { UUID } from 'src/exShared/util/uuid'
import { getPdfThumbUrlFromUrl } from 'src/state/ducks/files/saga'
import {
  updateTemplateNode,
  uploadInformationFiles,
} from 'src/state/ducks/templates/actions'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import { Container, InformationError, InformationUpload } from './dumb'

const URLInput = styled(Input)`
  flex-grow: 1;
`
const StyledContainer = styled(Container)`
  padding: 8px 16px;
  column-gap: 16px;
`

type Props = {
  question: TemplateQuestion
  isLoadingInformation: boolean
  errorInformation: string
  getInformationFileList: () => UploadFile[]
  onChangeInformationType: (informationType: InformationType) => void
  onChangeInformation: (info: any) => void
  onChangeURL: (url: string) => void
}

type InformationType = 'file' | 'url'

const getInformationType = (question: TemplateQuestion): InformationType => {
  switch (question.responseType) {
    case ResponseTypeEnum.INFORMATION: {
      return 'file'
    }
    case ResponseTypeEnum.URL: {
      return 'url'
    }
    default: {
      return 'file'
    }
  }
}

const InformationOption: React.FC<Props> = ({
  question,
  isLoadingInformation,
  errorInformation,
  getInformationFileList,
  onChangeInformationType,
  onChangeInformation,
  onChangeURL,
}) => {
  if (question === undefined) return null

  const informationType = getInformationType(question)
  const responseURL = question.responseURLs?.[0]

  return (
    <StyledContainer>
      <Select<InformationType>
        value={informationType}
        style={{ width: 120, flexShrink: 0 }}
        onChange={onChangeInformationType}
      >
        <Select.Option value={'file'}>ファイル</Select.Option>
        <Select.Option value={'url'}>URL</Select.Option>
      </Select>
      {question.responseType === ResponseTypeEnum.INFORMATION && (
        <>
          <div>
            <Spin spinning={isLoadingInformation}>
              <InformationUpload
                listType="picture"
                fileList={getInformationFileList()}
                onChange={onChangeInformation}
                accept={'.png,.jpg,.jpeg,.pdf'}
                beforeUpload={() => false}
              >
                <Button>
                  <UploadOutlined /> ファイルを選択
                </Button>
              </InformationUpload>
            </Spin>
          </div>
          <InformationError>{errorInformation}</InformationError>
        </>
      )}
      {question.responseType === ResponseTypeEnum.URL && (
        <>
          <URLInput
            value={responseURL?.url ?? ''}
            placeholder="httpsもしくはhttpから始まるURLを入力してください（例: https://example.com）"
            onChange={e => {
              onChangeURL(e.target.value)
            }}
          ></URLInput>
          <Tooltip
            placement="bottomLeft"
            title={
              <>
                設定したWebページにログインすると、ログイン状態が保持され、他のユーザーがログイン後のページを閲覧できる可能性があります。共有してはいけない情報を含む場合は注意してください。
              </>
            }
            arrowPointAtCenter
          >
            <InfoCircleOutlined />
          </Tooltip>
        </>
      )}
    </StyledContainer>
  )
}

type ContainerProps = {
  node: TemplateNodeSchema
}

export const InformationOptionContainer: React.FC<ContainerProps> = ({
  node,
}) => {
  const dispatch = useDispatch()

  const isLoadingInformation = useSelector(
    (state: RootState) =>
      state.templatesState.templatePages.isLoadingInformation,
    shallowEqual,
  )
  const [errorInformation, setErrorInformation] = useState<string>('')

  const handleChangeInformationType = useCallback(
    (informationType: InformationType) => {
      switch (informationType) {
        case 'file': {
          dispatch(
            updateTemplateNode(node.id, {
              ...node,
              question: {
                ...node.question,
                responseType: ResponseTypeEnum.INFORMATION,
                responseInformations: [],
                responseURLs: [],
              },
            }),
          )
          break
        }
        case 'url': {
          const responseURL = {
            url: '',
          }
          dispatch(
            updateTemplateNode(node.id, {
              ...node,
              question: {
                ...node.question,
                responseType: ResponseTypeEnum.URL,
                responseInformations: [],
                responseURLs: [responseURL],
              },
            }),
          )
          break
        }
      }
    },
    [dispatch, node],
  )

  const handleChangeInformation = useCallback(
    (info: any) => {
      setErrorInformation('')
      if (info.file.status === 'removed') {
        dispatch(
          updateTemplateNode(node.id, {
            ...node,
            question: {
              ...node.question,
              responseInformations: [],
            },
          }),
        )
        return
      }
      if (
        info.file.type !== 'application/pdf' &&
        info.file.size > getMBSize(2)
      ) {
        setErrorInformation('画像ファイルの最大容量は2MBです')
        return
      }

      // カジュアルにinputタグのaccept属性で指定してるファイル形式以外をアップロード出来ないようにバリデーションしておく
      const acceptedTypes = [
        'image/png',
        'image/jpeg',
        'application/pdf',
        // 👇️accept属性で指定していないが、このバリデーションを入れる前の既存の挙動でアップロード可能だったので、念の為許可している
        'image/gif',
      ]
      if (!acceptedTypes.includes(info.file.type)) {
        setErrorInformation('許可されていないファイル形式です')
        return
      }

      dispatch(uploadInformationFiles([info.file], node.id))
    },
    [dispatch, node],
  )

  const handleChangeURL = useCallback(
    (url: string) => {
      const responseURL = node.question?.responseURLs?.[0]
      // 回答種別がURLにもかからわずresponseURLがundefinedになる事象が発生したため、
      // undefinedの場合にもurlが変更できるようにした。
      // https://kaminashi.atlassian.net/browse/HPB-5228
      if (responseURL === undefined) {
        Sentry.captureException(new Error('responseURLが存在しません。'))
      }
      const newResponseURL: ResponseURL = {
        ...responseURL,
        url,
      }
      dispatch(
        updateTemplateNode(node.id, {
          ...node,
          question: {
            ...node.question,
            responseInformations: [],
            responseURLs: [newResponseURL],
          },
        }),
      )
    },
    [dispatch, node],
  )

  const getInformationFileList = useCallback(
    () =>
      (node.question?.responseInformations ?? []).map(
        (file: InformationFile) => {
          const maxFilename = 50
          const uploadFile = {
            uid: file.uuid ?? UUID(),
            size: file.size ?? 0,
            name: file.name?.slice(0, maxFilename) ?? '',
            url: file.url ?? '',
            ...(file.url &&
              file.url?.match('.pdf') && {
                //NOTE file.typeが空文字になっている場合があるため
                thumbUrl: getPdfThumbUrlFromUrl(file.url),
              }),
            ...(file?.type && {
              type: mime.getType(file.type),
            }),
          } as UploadFile
          return uploadFile
        },
      ),
    [node.question?.responseInformations],
  )

  if (node.question === undefined) {
    return null
  }

  return (
    <InformationOption
      question={node.question}
      isLoadingInformation={isLoadingInformation}
      errorInformation={errorInformation}
      onChangeInformationType={handleChangeInformationType}
      onChangeInformation={handleChangeInformation}
      onChangeURL={handleChangeURL}
      getInformationFileList={getInformationFileList}
    ></InformationOption>
  )
}
