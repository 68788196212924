import { css } from '@emotion/react'
import { useQueryClient } from '@tanstack/react-query'
import { ErrorCodeEnum } from '@ulysses-inc/harami_api_client'
import { Modal } from 'antd'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Spinner } from 'src/components/spinner/Spinner'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { invalidateFeatureFlagsQuery } from 'src/features/featureFlags/useFeatureFlags'
import { setUserPropertiesFromLocalStorage } from 'src/features/firebase/userProperties'
import { useSetGtmUserId } from 'src/features/gtm/useSetGtmUserId'
import { useSetSentryUserId } from 'src/features/sentry/useSetSentryUserId'
import {
  AuthError,
  useQueryLoginUserContext,
} from 'src/features/tanStackQuery/commonQueries/useQueryLoginUserContext'

type AuthCheckResult = 'passed' | 'failed' | 'unknown'

type Props = {
  children: ReactNode
}

/**
 * 認証が必要な画面を初回にマウントした際に以下を行う。
 * - ユーザーが認証済みであることを検証する。次項のAPIを呼び出せることをもって検証に代える。
 * - APIからユーザーコンテキストを取得したうえローカルストレージにセットする。
 *   (従前のコードを踏襲してローカルストレージに保持しているものの、特に意図や効能はないので、
 *    普通にTanStackのクエリを使うよう関係個所をリファクタしたい)
 */
export const AuthGuard = ({ children }: Props) => {
  useSetGtmUserId()
  useSetSentryUserId()

  const queryClient = useQueryClient()
  const [authCheckResult, setAuthCheckResult] =
    useState<AuthCheckResult>('unknown')

  const { data: loginUserContext, error } = useQueryLoginUserContext({
    noCache: true,
  })

  const showMessageIfNecessary = useCallback((error: Error): void => {
    if (!(error instanceof AuthError)) {
      Modal.info({
        title: '不明なエラー',
        content: 'ユーザー情報の取得に失敗したためログイン画面に遷移します。',
      })
      return
    }

    switch (error.code()) {
      case ErrorCodeEnum.CredentialsNotFoundError:
        return
      case ErrorCodeEnum.ServiceAssignmentRequiredError:
        Modal.info({
          title: '権限不足',
          content: error.userMessage(),
        })
        return
      case ErrorCodeEnum.InvalidAudienceError:
        Modal.info({
          title: '認証エラー',
          content: '再ログインが必要です',
        })
        return
      case ErrorCodeEnum.AccessTokenInactiveError:
        Modal.info({
          title: '認証期限切れ',
          content: error.userMessage(),
        })
        return
      default:
        Modal.info({
          title: '認証期限切れ',
          content: '認証期限が切れたため、再ログインしてください。',
        })
        return
    }
  }, [])

  useEffect(() => {
    // 判定は1回だけ行う
    if (authCheckResult !== 'unknown') return

    // 認証できなかった場合 (≒APIからデータが取得できなかった場合)
    if (error) {
      showMessageIfNecessary(error)
      setAuthCheckResult('failed')
      return
    }

    // 認証できた場合 (≒APIからデータが取得できた場合)
    if (loginUserContext) {
      localStorage.setItem(
        localStorageKeys.loginUserLoginId, // TODO: ID統合が完了したら削除
        loginUserContext.userLoginId,
      )
      localStorage.setItem(
        localStorageKeys.loginUserRumpCustomerMemberId,
        loginUserContext.rumpCustomerMemberId,
      )
      localStorage.setItem(
        localStorageKeys.loginUserUuid,
        loginUserContext.userUuid,
      )
      localStorage.setItem(
        localStorageKeys.loginUserName,
        loginUserContext.userName,
      )
      localStorage.setItem(
        localStorageKeys.loginUserRole,
        `${loginUserContext.userRole}`,
      )
      localStorage.setItem(
        localStorageKeys.loginCompanyContractPlan,
        `${loginUserContext.contractPlan}`,
      )
      localStorage.setItem(
        localStorageKeys.loginCompanyId,
        `${loginUserContext.companyId}`,
      )
      localStorage.setItem(
        localStorageKeys.loginCompanyName,
        loginUserContext.companyName,
      )
      localStorage.setItem(
        localStorageKeys.loginUserPlaceNodes,
        JSON.stringify(loginUserContext.userPlaceNodes),
      )
      localStorage.setItem(
        localStorageKeys.loginUserIsRumpRealmAdmin,
        JSON.stringify(loginUserContext.isRumpRealmAdmin ? 1 : 0), // bool 値は1 または 0 で保存する
      )

      setUserPropertiesFromLocalStorage()
      invalidateFeatureFlagsQuery(queryClient)

      setAuthCheckResult('passed')
      return
    }
  }, [
    authCheckResult,
    error,
    loginUserContext,
    queryClient,
    showMessageIfNecessary,
  ])

  switch (authCheckResult) {
    case 'unknown':
      return (
        <div css={styles.spinner}>
          <Spinner />
        </div>
      )
    case 'passed':
      return <Route>{children}</Route>
    case 'failed':
      return <Redirect to={'/login'} />
  }
}

const styles = {
  spinner: css`
    padding: 16px;
  `,
}
