import {
  AlertOutlined,
  CheckOutlined,
  CloseOutlined,
  NotificationOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Image,
  ResponseDatetime,
  ResponseDatetimeSubTypeEnum,
  ResponseEmployeeSubTypeEnum,
  ResponseTextSubTypeEnum,
  ResponseTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button, Checkbox, Modal, Select, Spin, Switch, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { useId, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getMBSize } from 'src/exShared/util/fileSize'
import convertDisplayFormulaCharacters from 'src/exShared/util/formula/convertDisplayFormulaCharacters'
import convertDisplayCharacters from 'src/exShared/util/numberRule/convertDisplayCharacters'
import { UUID } from 'src/exShared/util/uuid'
import { getBase64 } from 'src/libraries/upload/pictures'
import { uploadImagesRequestByFiles } from 'src/state/ducks/images/sagas'
import { showEditDeviateNotify as showEditDeviateNotifyAction } from 'src/state/ducks/templates/editDeviateNotify/actions'
import {
  selectIsGridLayout,
  selectQuestionNameByUuid,
} from 'src/state/ducks/templates/selectors'
import { RootState } from 'src/state/store'
import EditResponseFormulaDrawer from '../editResponseFormula/EditResponseFormulaDrawer'
import EditResponseNumberRuleDrawer from '../editResponseNumberRule/EditResponseNumberRuleDrawer'
import { MeasurementSettingsModal } from '../editResponseTimeMeasurementRule/MeasurementSettingsModal'
import { RuleDrawer as TimeMeasurementRuleDrawer } from '../editResponseTimeMeasurementRule/RuleDrawer'
import { DatetimeRule } from './DatatimeRule'
import { InformationOptionContainer } from './InformationOption'
import {
  Container,
  FormatLabel,
  InformationImagesError,
  InformationImagesText,
  InformationImagesWrapper,
  NoPaddingCheckbox,
  OptionColWrapper,
  OptionInformationImagesWrapper,
  OptionWrapper,
  RuleLabel,
  ScaleInput,
  ScaleLabel,
} from './dumb'

interface Props {
  node: TemplateNodeSchema
  parentNode: TemplateNodeSchema | null
  updateTemplateNode: (nodeId: number, changeNode: TemplateNodeSchema) => void
  showEditDeviateComment: (node: TemplateNodeSchema) => void
  isEmployeeCheck: boolean
}

/**
 * 質問単位の各種設定を行うためのUIを提供している神コンポーネント
 *
 * - ひな形編集画面における各質問のブロックのうち、質問名/質問種別などを表示している行を除いた他の部分すべてをこのコンポーネントで取り扱っている
 * - 質問種別によって様々なUIを出し分けている
 * - 確実に分割と整理が必要 🚨
 */
const QuestionOptionBar = (props: Props) => {
  const dispatch = useDispatch()

  const { templateNodes } = useSelector(
    (state: RootState) => state.templatesState.templatePages,
  )

  /**
   * antdのUploadコンポーネントをControlledに扱うために、
   * haramiのAPIから取得した画像に関するデータを基にして、
   * antdに求められている`UploadFile`という型に合うデータを生成するための関数と思われる
   *
   * <前提>
   * - 選択肢タイプの質問には画像を添付することができる
   * - 画像のアップロードにはantdのUploadコンポーネントが使われている
   * - 参考：https://4x.ant.design/components/upload/
   */
  const getInformationImagesFileList = () => {
    const informationImages = props.node.question?.informationImages ?? []
    return informationImages.map((image: Image) => {
      const uploadFile: UploadFile = {
        uid: image.uuid ?? UUID(),
        size: image.size ?? 0,
        name: image.name ?? '',
        url: image.url ?? '',
        type: 'image/jpeg',
        // 以下の理由により型エラーを無視する
        // - APIから取得した画像に関するデータはImage型であり、当然ながらファイルオブジェクトなど存在しない
        // - antdの提供する`RcFile`や`UploadFile`なる型についてドキュメントがなく対処のしようがない
        // - `originFileObj`という値はアプリ内で未利用である
        //
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        originFileObj: {},
      }
      return uploadFile
    })
  }

  const onChangeInformationImages = (
    info: any,
    setError: (error: string) => void,
    setLoading: (isLoading: boolean) => void,
  ) => {
    setError('')
    if (info.file.status === 'removed') return
    if (info.file.size > getMBSize(2)) {
      setError('画像ファイルの最大容量は2MBです')
      return
    }
    setLoading(true)
    uploadImagesRequestByFiles([info.file]).then((images: Image[]) => {
      setLoading(false)
      props.updateTemplateNode(props.node.id, {
        ...props.node,
        question: {
          ...props.node.question,
          informationImages: [
            ...(props.node.question?.informationImages ?? []),
            ...images,
          ],
        },
      })
    })
  }

  const onRemoveInformationImages = (uuid: string) => {
    props.updateTemplateNode(props.node.id, {
      ...props.node,
      question: {
        ...props.node.question,
        informationImages: (
          props.node.question?.informationImages ?? []
        ).filter(image => image.uuid !== uuid),
      },
    })
  }

  const hasRule = (responseType?: ResponseTypeEnum) =>
    responseType === ResponseTypeEnum.MULTIPLE_CHOICE ||
    responseType === ResponseTypeEnum.NUMBER ||
    responseType === ResponseTypeEnum.FORMULA ||
    responseType === ResponseTypeEnum.DATETIME

  const onChangeNumberRuleScale = (scale: string) => {
    props.updateTemplateNode(props.node.id, {
      ...props.node,
      question: {
        ...props.node.question,
        scale: scale,
      },
    })
  }

  const showEditDeviateNotify = (node: TemplateNodeSchema) => {
    dispatch(showEditDeviateNotifyAction(node))
  }

  const isGridLayout = useSelector(selectIsGridLayout, shallowEqual)

  const [isOpenResponseNumberRuleDrawer, setIsOpenResponseNumberRuleDrawer] =
    useState(false)
  const [isLoadingInformationImages, setIsLoadingInformationImages] =
    useState<boolean>(false)
  const [errorInformationImages, setErrorInformationImages] =
    useState<string>('')
  const [isOpenResponseFormulaDrawer, setIsOpenResponseFormulaDrawer] =
    useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewVisible, setPreviewVisible] = useState(false)

  const [isOpenMeasurementSettingsDrawer, setIsOpenMeasurementSettingsDrawer] =
    useState(false)
  const [isOpenTimeMeasurementRuleDrawer, setIsOpenTimeMeasurementRuleDrawer] =
    useState(false)

  const numberScaleLabelId = useId()
  const formulaScaleLabelId = useId()
  const textFormatLabelId = useId()
  const dateFormatLabelId = useId()

  const defaultResponseTimeMeasurement = {
    subType: ResponseDatetimeSubTypeEnum.DATETIME,
    startQuestionNodeUUID: '',
    endQuestionNodeUUID: '',
    rule: undefined,
  }
  const responseTimeMeasurement =
    (props.node.question?.responseTimeMeasurements ?? [])[0] ||
    defaultResponseTimeMeasurement
  /** 時間計測の設定ボタンの「〜から」の部分 */
  const tmStartQuestionName =
    useSelector((state: RootState) =>
      selectQuestionNameByUuid(
        state,
        responseTimeMeasurement.startQuestionNodeUUID,
      ),
    ) || '質問名なし'
  /** 時間計測の設定ボタンの「〜まで」の部分 */
  const tmEndQuestionName =
    useSelector((state: RootState) =>
      selectQuestionNameByUuid(
        state,
        responseTimeMeasurement.endQuestionNodeUUID,
      ),
    ) || '質問名なし'

  if (props.node?.question === undefined) return null

  const responseNumber = (props.node.question.responseNumbers ?? [])[0]
  const responseFormula = (props.node.question.responseFormulas ?? [])[0]
  const scale = props.node.question?.scale ?? ''

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  )

  const informationImagesfileList = getInformationImagesFileList()

  const responsetType = props.node.question?.responseType

  // 回答種別が「日時」の場合、ルール設定の下部に注意書きを表示する挙動にするため、
  // Container と OptionWrapper のスタイルを個別に設定している
  const containerTheme =
    responsetType !== ResponseTypeEnum.DATETIME
      ? {
          height: '40px',
        }
      : {
          maxHeight: '72px',
          minHeight: '40px',
          alignItems: 'start',
        }
  const optionWrapperStyle: React.CSSProperties | undefined =
    responsetType !== ResponseTypeEnum.DATETIME
      ? undefined
      : {
          height: '36px',
          lineHeight: '32px',
          marginTop: '4px',
        }

  return (
    <>
      <Container theme={containerTheme} data-testid="question-option-bar">
        {props.node.question?.responseType !==
          ResponseTypeEnum.TIME_MEASUREMENT && (
          <OptionWrapper style={optionWrapperStyle}>
            <Checkbox
              disabled={props.isEmployeeCheck}
              checked={props.node.question.isRequired === 1}
              onClick={() => {
                const isRequired = props.node.question?.isRequired ?? 0
                props.updateTemplateNode(props.node.id, {
                  ...props.node,
                  question: {
                    ...props.node.question,
                    isRequired: isRequired === 0 ? 1 : 0,
                  },
                })
              }}
            >
              必須
            </Checkbox>
          </OptionWrapper>
        )}
        {!isGridLayout && (
          <OptionWrapper style={optionWrapperStyle}>
            <Checkbox
              disabled={props.isEmployeeCheck}
              checked={props.node.question.isTimeDisplayed === 1}
              onClick={() => {
                const isTimeDisplayed =
                  props.node.question?.isTimeDisplayed ?? 0
                props.updateTemplateNode(props.node.id, {
                  ...props.node,
                  question: {
                    ...props.node.question,
                    isTimeDisplayed: isTimeDisplayed === 0 ? 1 : 0,
                  },
                })
              }}
            >
              時刻を記録に表示する
            </Checkbox>
          </OptionWrapper>
        )}
        {hasRule(props.node.question.responseType) &&
          props.node.question.responseType !== ResponseTypeEnum.DATETIME && (
            <>
              <OptionWrapper>
                <OptionColWrapper>
                  <Button
                    type="link"
                    onClick={() => props.showEditDeviateComment(props.node)}
                  >
                    <AlertOutlined />
                    逸脱時警告メッセージ
                  </Button>
                </OptionColWrapper>
              </OptionWrapper>
              <OptionWrapper>
                <OptionColWrapper>
                  <Button
                    type="link"
                    onClick={() => showEditDeviateNotify(props.node)}
                  >
                    <NotificationOutlined />
                    逸脱時通知先
                  </Button>
                </OptionColWrapper>
              </OptionWrapper>
            </>
          )}
        {props.node.question?.responseType ===
          ResponseTypeEnum.TIME_MEASUREMENT && (
          <>
            <OptionWrapper>
              <OptionColWrapper>
                <Button
                  type="link"
                  onClick={() => props.showEditDeviateComment(props.node)}
                >
                  <AlertOutlined />
                  逸脱時警告メッセージ
                </Button>
              </OptionColWrapper>
            </OptionWrapper>
            <OptionWrapper>
              <OptionColWrapper>
                <Button
                  type="link"
                  onClick={() => showEditDeviateNotify(props.node)}
                >
                  <NotificationOutlined />
                  逸脱時通知先
                </Button>
              </OptionColWrapper>
            </OptionWrapper>
            <OptionWrapper>
              <OptionColWrapper>
                <RuleLabel>ルール設定:</RuleLabel>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="link"
                  onClick={() => setIsOpenTimeMeasurementRuleDrawer(true)}
                >
                  ルールを追加
                </Button>
              </OptionColWrapper>
              <TimeMeasurementRuleDrawer
                isOpen={isOpenTimeMeasurementRuleDrawer}
                questionNode={props.node}
                rule={props.node.question?.responseTimeMeasurements?.[0]?.rule}
                onClose={() => setIsOpenTimeMeasurementRuleDrawer(false)}
              />
            </OptionWrapper>
          </>
        )}
        {props.node.question?.responseType ===
          ResponseTypeEnum.MULTIPLE_CHOICE &&
          !isGridLayout && (
            <OptionWrapper>
              <OptionColWrapper>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={props.node.question.isShowInformationImages === 1}
                  onClick={() => {
                    const isShowInformationImages =
                      props.node?.question?.isShowInformationImages ?? 0
                    const informationImages = isShowInformationImages
                      ? []
                      : props.node?.question?.informationImages
                    props.updateTemplateNode(props.node.id, {
                      ...props.node,
                      question: {
                        ...props.node.question,
                        isShowInformationImages:
                          isShowInformationImages === 0 ? 1 : 0,
                        informationImages,
                      },
                    })
                  }}
                />
                <InformationImagesText>画像を表示する</InformationImagesText>
              </OptionColWrapper>
            </OptionWrapper>
          )}
        {props.node.question.responseType === ResponseTypeEnum.NUMBER && (
          <OptionWrapper>
            <OptionColWrapper>
              <RuleLabel>ルール設定:</RuleLabel>
              {responseNumber !== undefined ? (
                <Button
                  type="link"
                  onClick={() => setIsOpenResponseNumberRuleDrawer(true)}
                  data-testid="edit-number-rule"
                >
                  {convertDisplayCharacters(scale, responseNumber) ||
                    'ルールを追加'}
                </Button>
              ) : (
                <Button
                  icon={<PlusCircleOutlined />}
                  type="link"
                  onClick={() => setIsOpenResponseNumberRuleDrawer(true)}
                  data-testid="edit-number-rule"
                >
                  ルールを追加
                </Button>
              )}
              <ScaleLabel id={numberScaleLabelId}>単位:</ScaleLabel>
              <ScaleInput
                name="scale"
                placeholder="℃"
                value={scale}
                maxLength={10}
                onChange={e => onChangeNumberRuleScale(e.target.value)}
                aria-labelledby={numberScaleLabelId}
              />
            </OptionColWrapper>
            <EditResponseNumberRuleDrawer
              isOpen={isOpenResponseNumberRuleDrawer}
              questionNode={props.node}
              response={responseNumber}
              onClose={() => setIsOpenResponseNumberRuleDrawer(false)}
            />
          </OptionWrapper>
        )}
        {props.node.question.responseType === ResponseTypeEnum.TEXT &&
          !isGridLayout && (
            <OptionWrapper>
              <FormatLabel id={textFormatLabelId}>フォーマット:</FormatLabel>
              <Select
                defaultValue={props.node.question?.responseTexts?.[0]?.subType}
                style={{ width: 120 }}
                onChange={(value: number) =>
                  props.updateTemplateNode(props.node.id, {
                    ...props.node,
                    question: {
                      ...props.node.question,
                      responseTexts: [
                        {
                          templateQuestionId: props.node.question?.id,
                          subType: value,
                        },
                      ],
                    },
                  })
                }
                aria-labelledby={textFormatLabelId}
              >
                <Select.Option
                  value={ResponseTextSubTypeEnum.SINGLE_LINE}
                  data-testid="select-format-item"
                >
                  １行
                </Select.Option>
                <Select.Option
                  value={ResponseTextSubTypeEnum.MULTIPLE_LINE}
                  data-testid="select-format-item"
                >
                  複数行
                </Select.Option>
              </Select>
            </OptionWrapper>
          )}
        {props.node.question.responseType === ResponseTypeEnum.DATETIME && (
          <>
            <OptionWrapper style={optionWrapperStyle}>
              <FormatLabel id={dateFormatLabelId}>フォーマット:</FormatLabel>
              <Select
                defaultValue={
                  props.node.question?.responseDatetimes?.[0]?.subType
                }
                style={{ width: 80 }}
                onChange={(value: number) =>
                  props.updateTemplateNode(props.node.id, {
                    ...props.node,
                    question: {
                      ...props.node.question,
                      responseDatetimes: [
                        {
                          templateQuestionId: props.node.question?.id,
                          subType: value,
                          rule: undefined,
                          isFixedToCurrentTime: false,
                        },
                      ],
                    },
                  })
                }
                aria-labelledby={dateFormatLabelId}
              >
                <Select.Option
                  value={ResponseDatetimeSubTypeEnum.DATETIME}
                  data-testid="select-format-datetime-item"
                >
                  日時
                </Select.Option>
                <Select.Option
                  value={ResponseDatetimeSubTypeEnum.DATE}
                  data-testid="select-format-datetime-item"
                >
                  日付
                </Select.Option>
                <Select.Option
                  value={ResponseDatetimeSubTypeEnum.TIME}
                  data-testid="select-format-datetime-item"
                >
                  時刻
                </Select.Option>
              </Select>
            </OptionWrapper>
            {/* 時間改ざん防止オプション */}
            {isGridLayout &&
              props.node.question?.responseDatetimes?.[0]?.subType !==
                ResponseDatetimeSubTypeEnum.DATE && (
                <div style={{ ...optionWrapperStyle, marginLeft: 24 }}>
                  <NoPaddingCheckbox
                    checked={
                      props.node.question?.responseDatetimes?.[0]
                        ?.isFixedToCurrentTime
                    }
                    onChange={e => {
                      const responseDatetime =
                        props.node.question?.responseDatetimes?.[0]
                      if (!responseDatetime) {
                        return
                      }

                      const newResponseDateTime: ResponseDatetime = {
                        ...responseDatetime,
                        isFixedToCurrentTime: e.target.checked,
                        rule: undefined,
                      }

                      props.updateTemplateNode(props.node.id, {
                        ...props.node,
                        question: {
                          ...props.node.question,
                          responseDatetimes: [newResponseDateTime],
                        },
                      })
                    }}
                  >
                    現在時刻のみ記録可能
                  </NoPaddingCheckbox>
                </div>
              )}
            {props.node.question?.responseDatetimes?.[0]?.subType !==
              ResponseDatetimeSubTypeEnum.TIME &&
              // 「現在時刻のみ記録可能」を有効にした場合、ルール設定は意味をなさないので非表示にする
              !props.node.question?.responseDatetimes?.[0]
                ?.isFixedToCurrentTime && (
                <>
                  <DatetimeRule
                    responseDatetimes={
                      props.node.question?.responseDatetimes || []
                    }
                    updateTemplateNode={(
                      newResponseDateTime: ResponseDatetime,
                    ) => {
                      props.updateTemplateNode(props.node.id, {
                        ...props.node,
                        question: {
                          ...props.node.question,
                          responseDatetimes: [newResponseDateTime],
                        },
                      })
                    }}
                  />
                  {props.node.question?.responseDatetimes?.[0]?.rule?.value !==
                    undefined && (
                    <OptionWrapper style={optionWrapperStyle}>
                      <Button
                        type="link"
                        onClick={() => props.showEditDeviateComment(props.node)}
                      >
                        <AlertOutlined />
                        逸脱時警告メッセージ
                      </Button>
                      <Button
                        type="link"
                        onClick={() => showEditDeviateNotify(props.node)}
                      >
                        <NotificationOutlined />
                        逸脱時通知先
                      </Button>
                    </OptionWrapper>
                  )}
                </>
              )}
          </>
        )}
        {props.node.question.responseType === ResponseTypeEnum.EMPLOYEE && (
          <OptionWrapper>
            <FormatLabel>入力方法:</FormatLabel>
            <Select
              defaultValue={
                props.node.question?.responseEmployees?.[0]?.subType
              }
              style={{ width: 200, marginRight: 8 }}
              onChange={(value: number) => {
                props.updateTemplateNode(props.node.id, {
                  ...props.node,
                  question: {
                    ...props.node.question,
                    responseEmployees: [
                      {
                        templateQuestionId: props.node.question?.id,
                        subType: value,
                        isMultiple:
                          props.node.question?.responseEmployees?.[0]
                            ?.isMultiple,
                      },
                    ],
                  },
                })
              }}
            >
              <Select.Option value={ResponseEmployeeSubTypeEnum.NAME}>
                従業員名による入力
              </Select.Option>
              <Select.Option value={ResponseEmployeeSubTypeEnum.CODE}>
                従業員コードによる入力
              </Select.Option>
            </Select>

            <Checkbox
              disabled={props.isEmployeeCheck}
              checked={
                props.node.question?.responseEmployees?.[0]?.isMultiple === 1
              }
              onClick={() => {
                props.updateTemplateNode(props.node.id, {
                  ...props.node,
                  question: {
                    ...props.node.question,
                    responseEmployees: [
                      {
                        templateQuestionId: props.node.question?.id,
                        subType:
                          props.node.question?.responseEmployees?.[0]?.subType,
                        isMultiple:
                          props.node.question?.responseEmployees?.[0]
                            ?.isMultiple === 1
                            ? 0
                            : 1,
                      },
                    ],
                  },
                })
              }}
            >
              複数名の選択を可能にする
            </Checkbox>
          </OptionWrapper>
        )}
        {props.node.question.responseType === ResponseTypeEnum.FORMULA && (
          <OptionWrapper>
            <OptionColWrapper>
              <RuleLabel>ルール設定:</RuleLabel>
              {responseFormula !== undefined ? (
                <Button
                  type="link"
                  onClick={() => setIsOpenResponseNumberRuleDrawer(true)}
                  data-testid="edit-number-rule"
                >
                  {convertDisplayCharacters(scale, responseFormula) ||
                    'ルールを追加'}
                </Button>
              ) : (
                <Button
                  icon={<PlusCircleOutlined />}
                  type="link"
                  onClick={() => setIsOpenResponseNumberRuleDrawer(true)}
                  data-testid="edit-number-rule"
                >
                  ルールを追加
                </Button>
              )}
              <ScaleLabel id={formulaScaleLabelId}>単位:</ScaleLabel>
              <ScaleInput
                name="scale"
                placeholder="℃"
                value={scale}
                maxLength={10}
                onChange={e => onChangeNumberRuleScale(e.target.value)}
                aria-labelledby={formulaScaleLabelId}
              />
            </OptionColWrapper>
            <EditResponseNumberRuleDrawer
              isOpen={isOpenResponseNumberRuleDrawer}
              questionNode={props.node}
              response={responseFormula}
              onClose={() => setIsOpenResponseNumberRuleDrawer(false)}
            />
          </OptionWrapper>
        )}
      </Container>
      {props.node.question?.responseType ===
        ResponseTypeEnum.TIME_MEASUREMENT && (
        <Container theme={{ height: '40px' }}>
          <OptionWrapper>
            <OptionColWrapper>
              {responseTimeMeasurement.startQuestionNodeUUID &&
              responseTimeMeasurement.endQuestionNodeUUID ? (
                <Button
                  type="link"
                  onClick={() => setIsOpenMeasurementSettingsDrawer(true)}
                >
                  {`${tmStartQuestionName}から ${tmEndQuestionName}まで`}
                </Button>
              ) : (
                <Button
                  icon={<PlusCircleOutlined />}
                  type="link"
                  onClick={() => setIsOpenMeasurementSettingsDrawer(true)}
                >
                  計測を設定する
                </Button>
              )}
            </OptionColWrapper>
            <MeasurementSettingsModal
              isOpen={isOpenMeasurementSettingsDrawer}
              questionNode={props.node}
              response={responseTimeMeasurement}
              onClose={() => setIsOpenMeasurementSettingsDrawer(false)}
            />
          </OptionWrapper>
        </Container>
      )}
      {props.node.question?.isShowInformationImages === 1 &&
        props.node.question?.responseType ===
          ResponseTypeEnum.MULTIPLE_CHOICE && (
          <Container>
            <OptionInformationImagesWrapper>
              <Spin spinning={isLoadingInformationImages}>
                <InformationImagesWrapper>
                  <Upload
                    beforeUpload={() => false}
                    listType="picture-card"
                    fileList={informationImagesfileList}
                    onPreview={handlePreview}
                    accept={'.png,.jpg,.jpeg'}
                    onChange={info => {
                      onChangeInformationImages(
                        info,
                        setErrorInformationImages,
                        setIsLoadingInformationImages,
                      )
                    }}
                    onRemove={(file: UploadFile<any>) => {
                      onRemoveInformationImages(file.uid)
                    }}
                  >
                    {(props.node.question?.informationImages ?? []).length >= 8
                      ? null
                      : uploadButton}
                  </Upload>
                  <InformationImagesError>
                    {errorInformationImages}
                  </InformationImagesError>
                </InformationImagesWrapper>
              </Spin>
            </OptionInformationImagesWrapper>
            <Modal
              open={previewVisible}
              footer={null}
              onCancel={() => setPreviewVisible(false)}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Container>
        )}
      {(props.node.question.responseType === ResponseTypeEnum.INFORMATION ||
        props.node.question.responseType === ResponseTypeEnum.URL) && (
        <InformationOptionContainer
          node={props.node}
        ></InformationOptionContainer>
      )}
      {props.node.question.responseType === ResponseTypeEnum.FORMULA && (
        <Container>
          <OptionWrapper>
            {responseFormula !== undefined &&
            responseFormula.tokens &&
            responseFormula.tokens.length > 0 ? (
              <Button
                type="link"
                onClick={() => setIsOpenResponseFormulaDrawer(true)}
              >
                {convertDisplayFormulaCharacters(
                  Object.values(templateNodes),
                  responseFormula.tokens,
                ) || '計算式を設定する'}
              </Button>
            ) : (
              <Button
                icon={<PlusCircleOutlined />}
                type="link"
                onClick={() => setIsOpenResponseFormulaDrawer(true)}
              >
                計算式を設定する
              </Button>
            )}
            {isOpenResponseFormulaDrawer && (
              <EditResponseFormulaDrawer
                questionNode={props.node}
                parentNode={props.parentNode}
                responseFormula={responseFormula}
                onClose={() => setIsOpenResponseFormulaDrawer(false)}
              />
            )}
          </OptionWrapper>
        </Container>
      )}
    </>
  )
}

export default QuestionOptionBar
