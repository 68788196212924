import {
  FeatureNameEnum,
  ResponseFormula,
  ResponseFormulaDecimalPoint,
  ResponseFormulaSubTypeEnum,
  ResponseNumber,
  ResponseNumberDecimalPoint,
  ResponseNumberSubTypeEnum,
  ResponseTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Form, Row } from 'antd'
import { FormikHelpers, useFormik } from 'formik'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useFeature } from 'src/features/featureFlags/useFeatureFlags'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { appEnv } from 'src/util/appEnv'
import { ButtonGroup } from './EditResponseNumberRuleDrawer.styled'
import FractionalDigitsRow, {
  MAX_FORMULA_FRACTIONAL_DIGITS,
  MAX_NUMBER_FRACTIONAL_DIGITS,
} from './FractionalDigitsRow'
import NormalValueRow from './NormalValueRow'

interface OwnProps {
  isOpen: boolean
  questionNode: TemplateNodeSchema
  response?: ResponseNumber | ResponseFormula
  onClose: () => void
}

export type NumberRuleProps = {
  hasNumberRule: boolean
  minimum?: number
  maximum?: number
  scale?: string
  subType?: ResponseNumberSubTypeEnum | ResponseFormulaSubTypeEnum
  decimalPoint?: ResponseNumberDecimalPoint | ResponseFormulaDecimalPoint
}

const EditResponseNumberRuleDrawerContainer: FC<OwnProps> = ({
  response,
  questionNode,
  onClose,
  isOpen,
}) => {
  // TODO: Feature Flags の削除
  const isAvailableResponseFormulaDecimalPoint =
    useFeature(FeatureNameEnum.FORMULA_DECIMAL_POINT).canUse === 'yes' ||
    appEnv.isLocal
  const dispatch = useDispatch()

  // 数値タイプの質問にルールを設定する
  const changeResponseNumberRule = (
    node: TemplateNodeSchema,
    scale: string,
    newResponse: Pick<
      ResponseNumber,
      'minimum' | 'maximum' | 'subType' | 'decimalPoint'
    >,
  ) => {
    dispatch(
      updateTemplateNode(node.id, {
        ...node,
        question: {
          ...node.question,
          scale: scale,
          responseNumbers: [newResponse],
        },
      }),
    )
  }

  // 計算式タイプの質問にルールを設定する
  const changeResponseFormulaRule = (
    node: TemplateNodeSchema,
    scale: string,
    originalResponse: ResponseFormula,
    newResponseParams: Pick<
      ResponseFormula,
      'minimum' | 'maximum' | 'subType' | 'decimalPoint'
    >,
  ) => {
    const newResponse: ResponseFormula = {
      ...originalResponse,
      ...newResponseParams,
    }
    dispatch(
      updateTemplateNode(node.id, {
        ...node,
        question: {
          ...node.question,
          scale: scale,
          responseFormulas: [newResponse],
        },
      }),
    )
  }

  // subType が 0 である場合にもルールなしの判定としたいが、
  // 0 は Enum に定義されておらず型エラーが発生するため、falsy かどうかで判定する
  const hasNumberRule = !!response?.subType

  const initialValues: NumberRuleProps = {
    hasNumberRule,
    minimum: response?.minimum,
    maximum: response?.maximum,
    scale: questionNode.question?.scale,
    subType: response?.subType,
    decimalPoint:
      isNumberResponse(response, questionNode) ||
      isAvailableResponseFormulaDecimalPoint
        ? {
            value: response?.decimalPoint?.value,
            isActive: response?.decimalPoint?.isActive,
          }
        : undefined,
  }

  const formikProps = useFormik({
    initialValues: initialValues,
    // TODO extract this off the component to make testable
    onSubmit: (
      {
        hasNumberRule,
        subType,
        minimum,
        maximum,
        decimalPoint,
        scale,
      }: NumberRuleProps,
      { setErrors, resetForm }: FormikHelpers<NumberRuleProps>,
    ) => {
      if (hasNumberRule) {
        if (!subType) {
          setErrors({ subType: 'ルールの設定を行ってください' })
          return
        }

        switch (subType) {
          case ResponseNumberSubTypeEnum.BETWEEN:
            if (minimum === undefined || minimum === null) {
              setErrors({ minimum: '最小値を入力して下さい' })
              return
            }
            if (isNaN(minimum)) {
              setErrors({ minimum: '最小値を数値で入力して下さい' })
              return
            }
            if (maximum === undefined || maximum === null) {
              setErrors({ maximum: '最大値を入力して下さい' })
              return
            }
            if (isNaN(maximum)) {
              setErrors({ maximum: '最大値を数値で入力して下さい' })
              return
            }
            if (minimum > maximum) {
              setErrors({ minimum: '最小値 ー 最大値 で入力して下さい' })
              return
            }
            break
          case ResponseNumberSubTypeEnum.LESS_THAN:
            if (maximum === undefined || maximum === null) {
              setErrors({ maximum: '最大値を入力して下さい' })
              return
            }
            if (isNaN(maximum)) {
              setErrors({ maximum: '最大値を数値で入力して下さい' })
              return
            }
            break
          case ResponseNumberSubTypeEnum.GREATER_THAN:
            if (minimum === undefined || minimum === null) {
              setErrors({ minimum: '最小値を入力して下さい' })
              return
            }
            if (isNaN(minimum)) {
              setErrors({ minimum: '最小値を数値で入力して下さい' })
              return
            }
            break
        }
      }
      if (
        isNumberResponse(response, questionNode) ||
        isAvailableResponseFormulaDecimalPoint
      ) {
        if (decimalPoint) {
          const { isActive, value } = decimalPoint

          if (isActive && value === undefined) {
            setErrors({
              decimalPoint: '小数点以下の桁数を入力してください。',
            })
            return
          }
          if (!!subType && isActive && value !== undefined) {
            const maximumDecimalPoint =
              String(maximum).split('.')[1]?.length || 0
            const minimumDecimalPoint =
              String(minimum).split('.')[1]?.length || 0
            const ruleDecimalPoint =
              maximumDecimalPoint > minimumDecimalPoint
                ? maximumDecimalPoint
                : minimumDecimalPoint

            if (value < ruleDecimalPoint) {
              setErrors({
                decimalPoint:
                  '小数点以下の桁数をルールの小数点桁数より大きい数値で入力してください。',
              })
              return
            }
          }
        }
      }

      if (subType === ResponseNumberSubTypeEnum.GREATER_THAN)
        maximum = undefined
      if (subType === ResponseNumberSubTypeEnum.LESS_THAN) minimum = undefined

      if (isNumberResponse(response, questionNode)) {
        changeResponseNumberRule(questionNode, scale ?? '', {
          minimum,
          maximum,
          subType: subType as ResponseNumber['subType'],
          decimalPoint,
        })
      } else if (isFormulaResponse(response, questionNode)) {
        changeResponseFormulaRule(questionNode, scale ?? '', response || {}, {
          minimum,
          maximum,
          subType: subType as ResponseFormula['subType'],
          decimalPoint,
        })
      }
      onClose()
      resetForm()
    },
    enableReinitialize: true,
  })

  const { handleSubmit, resetForm } = formikProps

  const onCloseOrCancel = () => {
    onClose()
    resetForm()
  }

  const onSubmit = () => handleSubmit()

  return (
    <Drawer
      title="ルールの設定"
      maskClosable={false}
      placement="right"
      onClose={onCloseOrCancel}
      open={isOpen}
      width="600"
    >
      <Form>
        <NormalValueRow {...{ formikProps }} />
        {(isNumberResponse(response, questionNode) ||
          isAvailableResponseFormulaDecimalPoint) && (
          <FractionalDigitsRow
            {...{ formikProps }}
            max={
              isNumberResponse(response, questionNode)
                ? MAX_NUMBER_FRACTIONAL_DIGITS
                : MAX_FORMULA_FRACTIONAL_DIGITS
            }
            questionType={
              isNumberResponse(response, questionNode) ? 'number' : 'formula'
            }
          />
        )}
        <Row style={{ justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button type="link" onClick={onCloseOrCancel}>
              キャンセル
            </Button>
            <Button type="primary" onClick={onSubmit}>
              保存
            </Button>
          </ButtonGroup>
        </Row>
      </Form>
    </Drawer>
  )
}

const isNumberResponse = (
  _response: OwnProps['response'],
  questionNode: TemplateNodeSchema,
): _response is ResponseNumber =>
  questionNode.question?.responseType === ResponseTypeEnum.NUMBER

const isFormulaResponse = (
  _response: OwnProps['response'],
  questionNode: TemplateNodeSchema,
): _response is ResponseFormula =>
  questionNode.question?.responseType === ResponseTypeEnum.FORMULA

export default EditResponseNumberRuleDrawerContainer
