import {
  AddApprovalsCommentRequest,
  AddApprovalsReportRequest,
  AddApprovalsStepRequest,
  Approval,
  ApprovalsApi,
  BulkApproveReportsRequest,
  DeleteApprovalRequest,
  GetApprovalReportsV2Request,
  GetApprovalRequest,
  ReportStatusEnum,
  User,
} from '@ulysses-inc/harami_api_client'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import {
  createApprovalSendEmail,
  createRemandSendEmail,
} from 'src/exShared/util/email/createSendEmail'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import BaseClient from '../../middleware/saga/baseClient'
import { handleHTTPError } from '../../middleware/saga/handleHttpError'
import { default as emailActions } from '../email/actions'
import interceptionsActions from '../interceptions/actions'
import { default as reportResultActions } from '../reports/reportResult/actions'
import { default as actions } from './actions'
import { getReportResult } from './selectors'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getApprovalRequest = (req: GetApprovalRequest) => {
  return baseClient
    .getApi(ApprovalsApi)
    .getApproval(req)
    .then(approval => approval)
    .catch(handleHTTPError)
}

const deleteApprovalFlowRequest = (req: DeleteApprovalRequest) => {
  return baseClient
    .getApi(ApprovalsApi)
    .deleteApproval(req)
    .catch(handleHTTPError)
}

const addApprovalStepRequest = (req: AddApprovalsStepRequest) => {
  return baseClient
    .getApi(ApprovalsApi)
    .addApprovalsStep(req)
    .catch(handleHTTPError)
}

const addApprovalCommentRequest = (req: AddApprovalsCommentRequest) => {
  return baseClient
    .getApi(ApprovalsApi)
    .addApprovalsComment(req)
    .catch(handleHTTPError)
}

const addApprovalReportRequest = (req: AddApprovalsReportRequest) => {
  return baseClient
    .getApi(ApprovalsApi)
    .addApprovalsReport(req)
    .then(approval => approval)
    .catch(handleHTTPError)
}

const getApprovalReportsV2Request = (req: GetApprovalReportsV2Request) => {
  return baseClient
    .getApi(ApprovalsApi)
    .getApprovalReportsV2(req)
    .then(response => response)
    .catch(handleHTTPError)
}

const bulkApproveReportsRequest = (req: BulkApproveReportsRequest) => {
  return baseClient
    .getApi(ApprovalsApi)
    .bulkApproveReports(req)
    .catch(handleHTTPError)
}

function* getApproval(action: ReturnType<typeof actions.getApproval>) {
  try {
    let approval: Approval = {}

    // 空文字列でリクエストすると404エラーになるので回避
    if (action.approvalUUID !== '') {
      approval = yield call(getApprovalRequest, {
        approvalUUID: action.approvalUUID,
      })
    }
    yield put(actions.getSuccessApproval(approval))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorApproval(error))
  }
}

function* deleteApproval(action: ReturnType<typeof actions.deleteApproval>) {
  try {
    let stepId = 0
    let approvalUUID = ''

    // 一次承認での差し戻しかどうか
    const remandInFirstStep = !action.arg.approvalUUID
    if (remandInFirstStep) {
      // 承認フローのデータをコピーする
      const param = {
        addApprovalReport: {
          reportUUID: action.arg.reportUUID,
          approvalFlowId: action.arg.approvalFlowId,
        },
      }
      const approval: Approval = yield call(addApprovalReportRequest, param)
      const steps = approval.steps ?? []
      stepId = steps[0].id ?? 0
      approvalUUID = approval.uuid
    } else {
      stepId = action.arg.currentStep.id ?? 0
      approvalUUID = action.arg.approvalUUID
    }

    // 差し戻ししたときの記録を残す
    if (stepId != 0) {
      // 差し戻しコメント
      yield call(addApprovalCommentRequest, {
        addApprovalComment: { stepId: stepId, comment: action.arg.comment },
      })
      yield call(addApprovalStepRequest, { stepId: stepId })
    }

    // 関連を削除
    yield call(deleteApprovalFlowRequest, { approvalUUID: approvalUUID })

    // レポートステータスを差し戻しに更新する
    const { reportId } = yield select(getReportResult)
    if (reportId) {
      yield put(
        reportResultActions.updateReportStatus(
          reportId,
          ReportStatusEnum.APPROVAL_REMAND,
        ),
      )
      yield put(
        reportResultActions.changeReportStatus(
          ReportStatusEnum.APPROVAL_REMAND,
        ),
      )
    }

    // メール送信
    let userUUIDs: string[] = []
    if (remandInFirstStep) {
      // 一次承認の差し戻しの場合、担当者にメール送信
      userUUIDs = action.arg.assignees.map(assignee => assignee.uuid ?? '')
    } else {
      // 最初のステップの承認者にメール送信
      userUUIDs = (action.arg.firstStep.approvers ?? []).map(
        (user: User) => user.uuid ?? '',
      )
    }

    if (userUUIDs.length > 0) {
      yield put(
        emailActions.requestSendEmail(
          createRemandSendEmail(
            action.arg.url,
            action.arg.comment,
            userUUIDs,
            action.arg.reportName,
            action.arg.placeName,
            action.arg.reportDate,
            action.arg.remandedUserName,
          ),
          remandInFirstStep
            ? '担当者にメール送信しました'
            : '最初の承認者にメール送信しました',
        ),
      )
    }
    // 関連を削除するのでapprovalを初期化しておく
    yield put(actions.getSuccessApproval({}))
    yield put(actions.deleteSuccessApproval())
    yield put(
      notificationServiceActions.showNotification({
        message: '差し戻しをしました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.deleteErrorApproval(error))
  }
}

// 2回目以降の承認時に呼ぶ
function* addApprovalStep(action: ReturnType<typeof actions.addApprovalStep>) {
  try {
    yield call(addApprovalStepRequest, { stepId: action.currentStep.id ?? 0 })
    // レポートステータスを完了に更新する
    if (action.reportUUID && !action.nextStep?.approvers) {
      yield put(
        reportResultActions.updateReportStatus(
          action.reportUUID,
          ReportStatusEnum.COMPLETE,
        ),
      )

      yield put(
        reportResultActions.changeReportStatus(ReportStatusEnum.COMPLETE),
      )
    }
    yield put(actions.addSuccessApprovalStep())
    const userUUIDs = (action.nextStep?.approvers ?? []).map(
      (user: User) => user.uuid ?? '',
    )
    const { report } = yield select(getReportResult)
    if (userUUIDs.length > 0) {
      yield put(
        emailActions.requestSendEmail(
          createApprovalSendEmail(userUUIDs, {
            name: report.name,
            assigneeName: report.assignee.name,
            placeName: report.place.name,
            hasDeviate: report.isInvalid !== 0,
            url: action.url,
          }),
          '次の承認者にメール送信しました',
        ),
      )
    }
    // 承認データの取得
    yield put(actions.getApproval(action.approvalUUID))
    yield put(
      notificationServiceActions.showNotification({ message: '承認しました' }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.addErrorApprovalStep(error))
  }
}

// 初回の承認時に呼ぶ
function* addApprovalReport(
  action: ReturnType<typeof actions.addApprovalReport>,
) {
  try {
    // 承認フローのデータをコピーする
    const param = {
      addApprovalReport: {
        reportUUID: action.reportUUID,
        approvalFlowId: action.approvalFlowId,
      },
    }
    const approval: Approval = yield call(addApprovalReportRequest, param)
    // 初回ステップの承認を実施
    const steps = approval.steps ?? []
    yield put(
      actions.addApprovalStep(
        action.reportUUID,
        approval.uuid ?? '',
        steps[0],
        steps[1],
        action.url,
      ),
    )
    yield put(actions.addSuccessApprovalReport())
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.addErrorApprovalReport(error))
  }
}

function* getApprovalReports(
  action: ReturnType<typeof actions.getApprovalReports>,
) {
  try {
    const response = yield call(getApprovalReportsV2Request, {
      ...action.request,
      approvalReportFilter: { ...action.filter },
    })
    yield put(actions.getSuccessApprovalReports(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.getErrorApprovalReports(error))
  }
}

function* bulkApproveReports(
  action: ReturnType<typeof actions.bulkApproveReports>,
) {
  try {
    const response = yield call(bulkApproveReportsRequest, {
      ...action.request,
    })
    yield put(actions.getSuccessApprovalReports(response))
    yield put(
      notificationServiceActions.showNotification({
        message: 'レポートの一括承認を実行しました',
      }),
    )
    yield put(actions.bulkSuccessApproveReports())
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error))
    yield put(actions.bulkErrorApproveReports(error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_APPROVAL, getApproval),
  takeEvery(ActionTypes.REQUEST_DELETE_APPROVAL, deleteApproval),
  takeEvery(ActionTypes.REQUEST_ADD_APPROVAL_STEP, addApprovalStep),
  takeEvery(ActionTypes.REQUEST_ADD_APPROVAL_REPORT, addApprovalReport),
  takeEvery(ActionTypes.REQUEST_GET_APPROVAL_REPORTS, getApprovalReports),
  takeEvery(ActionTypes.REQUEST_BULK_APPROVE_REPORTS, bulkApproveReports),
]

export default sagas
